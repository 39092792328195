.catalog {
  display: grid;
  grid-gap: 0 2.5%;
  grid-template-columns: repeat(10, 1fr);
  @media screen and (max-width: 1600px) {
    grid-gap: 0 2%;
  }
  @media screen and (max-width: 1450px) {
    grid-template-columns: repeat(9, 1fr);
  }

  @media screen and (max-width: 1300px) {
    grid-template-columns: repeat(8, 1fr);
  }
  @media screen and (max-width: 1100px) {
    grid-template-columns: repeat(7, 1fr);
  }
  @media screen and (max-width: 979px) {
    grid-gap: 0 2.5%;
    grid-template-columns: repeat(5, 1fr);
  }
  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3vw;
    margin: 0 -16px;
    padding: 0 16px 24px;
    border-bottom: 1px solid #f3f3f3;
  }
  @media screen and (max-width: 576px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 16px 0;
  }
}
.category {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px;
  border-radius: 6px;
  transition: all 0.15s;
  cursor: pointer;

  @media screen and (max-width: 767px) {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
  }

  &:hover {
    z-index: 1;
    @media screen and (min-width: 768px) {
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
      background-color: #fff;
    }

    .categoryTitle,
    .categoryIcon {
      color: var(--primary-color, #af1ebe);
    }
  }
}

.categoryHidden {
  display: none;
}

.categoryImage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-height: 120px;
  max-width: 102px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  svg {
    max-width: 278px;
    width: 100%;
    height: auto;
    color: #b3b3b3;
  }

  img {
    height: 102px;
    width: 102px;
    object-fit: contain;
  }

  @media screen and (max-width: 767px) {
    margin-left: 0;
    margin-right: 16px;
    max-width: 9vw;
    height: auto;

    img {
      height: 64px;
      width: 64px;
    }
  }

  @media screen and (max-width: 640px) {
    img {
      height: 55px;
      width: 55px;
    }
  }

  @media screen and (max-width: 576px) {
    img {
      height: 50px;
      width: 50px;
    }
  }
}
.categoryTitle {
  text-align: center;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  transition: color 0.2s;

  @media screen and (min-width: 768px) {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    height: 100%;
    max-height: 34px;
  }
  @media screen and (max-width: 767px) {
    font-size: 16px;
    font-weight: 400;
  }
}
.categoryIcon {
  display: none;
  margin-left: auto;
  transform: rotate(90deg);
  transition: color 0.2s;

  @media screen and (max-width: 767px) {
    display: block;
  }
}
