@import "~global/styles/Home.module.scss";

.bannerBlock {
  height: auto;
  margin-top: 24px;
  border-radius: 8px;
  overflow: hidden;

  &.clickable {
    cursor: pointer;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }

  img {
    height: 100%;
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    object-position: center;
    vertical-align: middle;
  }
}

.bannerBlockMobile {
  display: none;
  margin-bottom: 8px;
  border-radius: 8px;
  height: 36vw;
  overflow: hidden;

  &.clickable {
    cursor: pointer;
  }

  img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    display: block;
    height: auto;
  }
}
