@import 'typography.scss';

.filtersButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  min-height: 28px;
  text-align: center;
  color: var(--primary-color, #af1ebe);
  font-size: $smallMobileFontSize;
  border: 1px solid #ebebeb;
  border-radius: 32px;
  background-color: #fff;
  white-space: nowrap;
  transition: background-color 0.4s, border-color 0.4s;
  cursor: default;

  @media screen and (max-width: 767px) {
    display: none;
  }

  &:hover {
    border-color: #fafafa;
    background-color: #fafafa;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    padding: 0;
  }

  svg {
    width: 14px;
    height: 14px;
    color: var(--primary-color, #af1ebe);
  }

  &.active {
    border: 1px solid var(--primary-color, #af1ebe);

    &:hover {
      border-color: var(--primary-color, #af1ebe);
      background-color: rgba(175, 30, 190, 0.075);
    }
  }
  &.remove {
    border: none;
    background-color: #f2f2f2;

    &:hover {
      background-color: #d9d9d9;
    }
  }
}
