.filtersModal {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 690;
  position: fixed;
  pointer-events: none;
  background-color: rgba(#333, 0.4);
  transition: opacity 0.1s ease-in-out;
  opacity: 0;
}
.filtersModalOpen {
  opacity: 1;
  pointer-events: auto;

  .filtersContent {
    transform: translateX(0);
  }
}
.filtersContent {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  max-width: 320px;
  width: 320px;
  height: 100%;
  padding-bottom: 12px;
  transition: transform 0.2s ease-in-out;
  transform: translateX(360px);
  background-color: #fff;
  z-index: 700;
}

.filterHeader {
  padding: 16px;
}
.filtersClose {
  padding: 6px 0;
  text-align: right;
  cursor: pointer;
}
.filtersTitle {
  margin-top: 8px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.filtersBlock {
  overflow: auto;
}
.filtersAccordeon {
  border-bottom: none;
  overflow: hidden;
  padding-bottom: 10px;

  > div:first-child:hover {
    transition: color 0.2s;

    &:hover {
      color: var(--primary-color);
    }
  }
}

.filtersButtons {
  padding: 16px;

  button + button {
    margin-top: 8px;
  }

  button:last-child {
    color: var(--primary-color, #af1ebe);
    background-color: rgb(242, 242, 242);
    font-weight: normal;
  }
}

.listWrapper {
  display: block;
  max-height: 315px;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 6px;
    margin-right: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: #ebebeb;
    border-radius: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #909090;
    border-radius: 8px;
  }
}

.filterLoader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  padding-top: 100px;
  z-index: 1000;
}
