@import "~global/styles/variables.global.scss";
@import '../../../../global/styles/typography.scss';

.filtersBlock {
   position: relative;
   z-index: 15;
   display: flex;
   margin-top: 16px;
}

.filters {
   display: flex;
   flex-wrap: wrap;
   margin: -4px;

   >* {
      margin: 4px;
   }

   @media screen and (max-width: 767px) {
      display: none;
   }
}

.filtersMobileButton {
   display: none;
   position: relative;
   align-items: center;
   font-size: 16px;
   color: $black;

   @media screen and (max-width: 767px) {
      display: flex;
   }

   &:hover {
      color: var(--primary-color, #af1ebe);
   }

   svg {
      margin-right: 8px;
      color: var(--primary-color, #af1ebe);
   }
}

.filtersBadge {
   position: absolute;
   top: 0px;
   left: 13px;
   display: flex;
   align-items: center;
   justify-content: center;
   min-width: 16px;
   min-height: 16px;
   padding: 0 2px;
   background: var(--primary-color, #af1ebe);
   color: #fff;
   font-size: 12px;
   font-weight: 700;
   line-height: inherit;
   text-align: center;
   box-sizing: border-box;
   border-radius: 50%;
   transition: color 0.2s;
}

.sort {
   margin-left: auto;
}

.sortItem {
   @media screen and (max-width: 767px) {
      display: flex;

      >div:first-child {
         padding: 0;
         font-size: 16px;
         border: none;
         background-color: transparent;

         &:hover {
            color: var(--primary-color, #af1ebe);
            background-color: transparent;
         }

         svg {
            height: 20px;
            color: var(--primary-color, #af1ebe);
         }
      }

      >div:last-child button {
         color: #1a1a1a;
      }
   }
}