@import '../../../global/styles/variables.global.scss';
@import '../../../global/styles/typography.scss';

.breadcrumbs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 8px;
  padding: 16px 0;

  @media screen and (max-width: 767px) {
    margin-top: 0px;
    padding: 8px 0 10px;
    line-height: 1.3;
  }
}

.breadcrumb {
  font-size: 14px;
  line-height: 1;
  transition: color 0.15s;

  @media screen and (max-width: 767px) {
    font-size: $smallMobileFontSize;
    color: black;
  }

  &:last-child:not(.clickable) {
    color: #b3b3b3;
    pointer-events: none;

    @media screen and (max-width: 767px) {
      color: #808080;
    }
  }

  &:hover {
    color: var(--primary-color, #9b1ba8);
  }
}

.breadcrumbsSeparator {
  margin: 0 16px;
  padding-bottom: 2px;
  color: #b3b3b3;

  @media screen and (max-width: 767px) {
    margin-left: 5px;
    margin-right: 5px;
    color: #808080;
  }
}

.myToBackButton {
  color: $logo-color;
  display: flex;
  padding-right: 20px;
  align-items: center;
  cursor: pointer;

  & svg {
    width: 20px;
    height: 20px;
    margin-right: 15px;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
}