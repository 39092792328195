.categoryTags {
  display: flex;
  padding: 24px 0 14px;
  flex-wrap: wrap;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;

  &::-webkit-scrollbar {
    display: none;
    scrollbar-width: none;
  }

  &.header {
    margin-bottom: 40px;
  }

  &.footer {
    margin-top: 40px;
    margin-bottom: -20px;
  }
}

.filtersButton {
  button {
    padding: 8px 12px;
    margin-bottom: 10px;
    min-height: unset;

    @media screen and (max-width: 767px) {
      display: flex;
    }
  }
}
