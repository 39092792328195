@import "~global/styles/variables.global.scss";

.filtersBlock {
    position: relative;
    z-index: 15;
    display: flex;
    margin-top: 16px;
}
.filters {
    display: flex;
    flex-wrap: wrap;
    margin: -4px;

    > * {
        margin: 4px;
    }

    @media screen and (max-width: 767px) {
        display: none;
    }
}
