.goodsBlock {
  margin-top: 40px;
  padding-bottom: 72px;

  @media screen and (max-width: 1024px) {
    margin-top: 28px;
  }
  @media screen and (max-width: 767px) {
    margin-top: 24px;
  }
}

.goodsFilters {
  display: flex;
  column-gap: 16px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    row-gap: 10px;
  }
}

.filtersBlock {
  margin-top: 16px;
}

.goodsList {
  position: relative;
  margin-top: 48px;

  &.busy {
    opacity: 0.5;
    pointer-events: none;
  }

  @media screen and (max-width: 1024px) {
    margin-top: 32px;
  }
}
.goodsListEmpty {
  margin-top: 16px;
  padding: 60px 20px;
  color: #bdc3c7;
  text-align: center;
  font-size: 115%;
  background: #f2f2f2;
  border-radius: 8px;
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 92px;
}

.toggleContent {
  position: relative;
  display: flex;
}

.toggleText {
  color: #FFFFFF;
  font-weight: 600;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  z-index: 1;
  font-size: 16px;
  line-height: 12px;

  @media screen and (max-width: 767px) {
     font-size: 14px;

  }
}